import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import {
  Facebook,
  Instagram,
  YouTube,
  WhatsApp,
  ArrowUpward,
} from "@mui/icons-material";
import { shades } from "../../theme";

function ContactInfo({
  contactNumber,
  address,
  mapsLink,
  onCookieSettingsClick,
}) {
  return (
    <Grid
      item
      xs={12}
      md={3}
      className="footer-column"
      sx={{ marginBottom: { xs: "30px", md: "0" } }}
    >
      <Typography variant="h3" mb="10px">
        Contact
      </Typography>
      <Typography variant="body1" mb="10px">
        Adres:{" "}
        <a
          href={mapsLink}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Adres: ${address}`}
        >
          {address}
        </a>
      </Typography>
      {/* Hide these links on mobile */}
      <Typography
        variant="body1"
        sx={{ display: { xs: "none", md: "block" } }}
        mb="10px"
      >
        <a
          href="/verkoopvoorwaarden"
          aria-label="Bekijk onze verkoopvoorwaarden"
        >
          Verkoopvoorwaarden
        </a>{" "}
        &{" "}
        <a href="/privacy-policy" aria-label="Bekijk onze privacyverklaring">
          Privacyverklaring
        </a>
      </Typography>
      <Typography variant="body1" sx={{ display: { xs: "none", md: "block" } }}>
        <a
          href="#cookiesettings"
          onClick={onCookieSettingsClick}
          aria-label="Cookie-instellingen"
        >
          Cookie-instellingen
        </a>
      </Typography>
    </Grid>
  );
}

function OpeningHours({ openingHours }) {
  return (
    <Grid
      item
      xs={12}
      md={3}
      className="footer-column"
      sx={{
        marginBottom: { xs: "30px", md: "0" },
        display: { xs: "none", md: "block" }, // Hide opening hours on mobile
      }}
    >
      <Typography variant="h3" mb="10px">
        Openingstijden
      </Typography>
      <ul style={{ paddingInlineStart: "0", margin: "0" }}>
        {openingHours.map((time, index) => (
          <li key={index} style={{ listStyle: "none", marginBottom: "10px" }}>
            {time}
          </li>
        ))}
      </ul>
    </Grid>
  );
}

function SocialMediaLinks() {
  return (
    <Grid
      item
      xs={12}
      md={3}
      className="footer-column"
      sx={{ marginBottom: { xs: "30px", md: "0" } }}
    >
      <Typography variant="h3" mb="10px">
        Volg ons!
      </Typography>
      <Box className="socials-container" sx={{ display: "flex", gap: "10px" }}>
        <a
          data-social="Instagram"
          style={{
            "--accent-color": "#fe107c",
            backgroundColor: "white",
            color: "#fe107c",
          }}
          href="https://www.instagram.com/vuurwerkbruchem/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Volg ons op Instagram"
        >
          <Instagram aria-hidden="true" />
        </a>
        <a
          data-social="Facebook"
          style={{
            "--accent-color": "#106bff",
            backgroundColor: "white",
            color: "#106bff",
          }}
          href="https://www.facebook.com/vuurwerkbruchem.nl/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Volg ons op Facebook"
        >
          <Facebook aria-hidden="true" />
        </a>
        <a
          data-social="YouTube"
          style={{
            "--accent-color": "red",
            backgroundColor: "white",
            color: "red",
          }}
          href="https://www.youtube.com/@vuurwerkbruchem"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Volg ons op YouTube"
        >
          <YouTube aria-hidden="true" />
        </a>
        <a
          data-social="WhatsApp"
          style={{
            "--accent-color": "#25D366",
            backgroundColor: "white",
            color: "#25D366",
          }}
          href="https://wa.me/31616175368"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Neem contact op via WhatsApp"
        >
          <WhatsApp aria-hidden="true" />
        </a>
      </Box>
    </Grid>
  );
}

function ScrollToTop({ isVisible }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    isVisible && (
      <IconButton
        onClick={scrollToTop}
        sx={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: shades.secondary[500],
          color: "white",
          "&:hover": {
            backgroundColor: shades.secondary[700],
          },
        }}
        aria-label="Scroll naar boven"
      >
        <ArrowUpward />
      </IconButton>
    )
  );
}

function Footer({ onCookieSettingsClick }) {
  const currentYear = new Date().getFullYear();
  const [isVisible, setIsVisible] = useState(false);
  const theme = useTheme();

  const contactNumber = "0418700511";
  const address = "Dorpsstraat 20, 5314 AD Bruchem";
  const mapsLink = "https://maps.app.goo.gl/u7RJnsurZ9f4Q4QB6";

  const openingHours = [
    "28 december: 09:00 - 21:00",
    "30 december: 09:00 - 21:00",
    "31 december: 09:00 - 17:00",
  ];

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    const debounce = (func, wait = 100) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(null, args);
        }, wait);
      };
    };

    const debouncedToggleVisibility = debounce(toggleVisibility);
    window.addEventListener("scroll", debouncedToggleVisibility);
    return () =>
      window.removeEventListener("scroll", debouncedToggleVisibility);
  }, []);

  return (
    <Box
      marginTop="50px"
      padding="10px 0"
      backgroundColor={theme.palette.background.paper}
      color={theme.palette.text.primary}
      sx={{
        "& a": { textDecoration: "none", color: "inherit" },
        "@media (max-width: 768px)": {
          padding: "20px 10px",
        },
      }}
    >
      <Grid
        container
        spacing={2}
        width="100%"
        margin="auto"
        maxWidth="80%"
        justifyContent="space-between"
      >
        <Grid
          item
          xs={12}
          md={3}
          className="footer-column"
          sx={{ marginBottom: { xs: "30px", md: "0" } }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            mb="10px"
            color={shades.secondary[500]}
          >
            VUURWERK BRUCHEM
          </Typography>
          {/* Hide descriptive text and logo on mobile */}
          <Typography
            variant="body1"
            className="desktop-text"
            sx={{
              display: { xs: "none", md: "block" },
            }}
          >
            Het beste vuurwerk in de Bommelerwaard! Grootste assortiment voor de
            laagste prijs. Parkeren voor de deur.
          </Typography>
          <Typography
            variant="body1"
            className="mobile-text"
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            Het beste vuurwerk in de Bommelerwaard! Grootste assortiment voor de
            laagste prijs. Parkeren voor de deur.
          </Typography>
          <Box
            mt="10px"
            sx={{
              display: { xs: "none", md: "block" }, // Hide the logo on mobile
              textAlign: { xs: "left", md: "left" },
            }}
          >
            <a
              href="https://vuurwerkcheck.nl/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Bekijk Vuurwerk Check website"
            >
              <img
                src="/logo-vuurwerkcheck.webp"
                alt="Vuurwerk Check Logo"
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
              />
            </a>
          </Box>
        </Grid>
        <OpeningHours openingHours={openingHours} />
        <SocialMediaLinks />
        <ContactInfo
          contactNumber={contactNumber}
          address={address}
          mapsLink={mapsLink}
          onCookieSettingsClick={onCookieSettingsClick}
        />
      </Grid>
    </Box>
  );
}

export default Footer;
